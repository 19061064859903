<template>
  <div class="forums-lists">
    <div class="begin">
      <div class="table-header text-black bg-white rounded shadow px-6 py-4 mt-1 flex items-center">
        <div class="flex-one item-color w-1/2 mr-4">
          {{ forumList.forum.title }}
        </div>
        <div class="flex-one item-color w-2/5 mr-4">
          {{ forumList.creator._fieldsProto.fullname.stringValue }}
        </div>
        <div class="flex-one item-color w-1/6">
          {{ forumList.forum.membersId.length + ' participants' }}
        </div>
        <div class="flex-one item-color w-1/12">
          <div class="icones flex items-center">
            <div class="update cursor-pointer" @click="deleteAdmin">
              <svg-icon
                name="ic_feather-trash"
                original
                class="w-5 h-5"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <success-add
      v-if="successAdd"
      :message="$t('forumDeletedSuccess')"
      :button-label="$t('goBackAddMemberButton')"
      @closeSuccess="closeSuccess"
    />
  </div>
</template>

<script>
import http from '../../../plugins/http'
import apiRoutes from '../../../router/api-routes'
import SuccessAdd from '../success-add'
export default {
  name: 'forums-lists',
  components: { SuccessAdd },
  props: {
    forumList: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      successAdd: false
    }
  },
  created () {
    // console.log(this.forumList.forum.id)
  },
  methods: {
    closeSuccess () {
      this.successAdd = false
      // window.location.reload()
    },
    deleteAdmin () {
      http.delete(apiRoutes.baseURL + apiRoutes.deleteForum, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        },
        data: {
          id: this.forumList.forum.id
        }
      }).then(response => {
        console.log(response)
        this.$emit('openSuccessAdd', true)
        // this.successAdd = true
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .table-header:hover {
    background: rgba(1,101,200,0.1);
    border-left: 2px solid $pass_marron;
    box-shadow: -6px 0px 6px 0px rgba(51, 50, 50, 0.45);
  }
  .svg-fill {
    fill: transparent;
  }
</style>
