<template>
  <div class="forum">
    <div class="begin">
      <div class="header flex items-center justify-end">
        <div class="flex-one current-month ">
          <div class="search-bloc flex items-center justify-between">
            <div class="recherche-name">
              <div class="relative recherche justify-center items-center pl-8 pr-4 py-4 bg-white shadow rounded">
                <div class="icon-search absolute inset-y-0 left-0 flex items-center px-4">
                  <svg-icon
                    name="ic_search"
                    original
                    class="icon w-4 h-4"
                  />
                </div>
                <div class="search-input ml-4">
                  <label>
                    <input
                      v-model="search"
                      type="text"
                      :placeholder="$t('searchForum')"
                      class="recherche-input"
                    >
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><br>
      <div class="user-list bg-white rounded font-semibold shadow p-6 flex items-center">
        <div class="flex-one item-color w-1/2 mr-4">{{ $t('forum') }}</div>
        <div class="flex-one item-color w-2/5 mr-4">{{ $t('createdBy') }}</div>
        <div class="flex-one item-color w-1/6">{{ $t('numberParticipant') }}</div>
        <div class="flex-one item-color w-1/12"></div>
      </div>
      <div class="loading" :class="{ 'not_empty': isLoading === false }">
        <loading
          :active="isLoading"
          :can-cancel="true"
          :on-cancel="onCancel"
          :is-full-page="fullPage"
          loader="dots"
          color="#0B5382"
          background-color="transparent"
        />
      </div>
      <div v-if="nothing && !isLoading" class="no-student text-center text-lg py-32">
        {{ $t('noForumAdded') }}
      </div>
      <div v-if="!isLoading" class="forum-listing">
        <forums-lists
          v-for="(item, index) in sortedArrayUser"
          :key="index"
          :forum-list="item"
        />
      </div>
    </div>
    <success-add
      v-if="successAdd"
      :message="$t('forumDeletedSuccess')"
      :button-label="$t('goBackAddMemberButton')"
      @closeSuccess="closeSuccess"
    />
  </div>
</template>

<script>
import ForumsLists from '../../components/helper/forum/forums-lists'
import http from '../../plugins/http'
import apiRoutes from '../../router/api-routes'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import SuccessAdd from '@/components/helper/success-add'
export default {
  name: 'index',
  components: { SuccessAdd, ForumsLists, Loading },
  data () {
    return {
      isLoading: true,
      fullPage: false,
      search: '',
      forums: [],
      nothing: false,
      successAdd: false
    }
  },
  created () {
    this.getAllForums()
  },
  computed: {
    sortedArrayUser: function () {
      let sorted = []
      for (let i = 0; i < this.forums.length; i++) {
        sorted.push(this.forums[i])
      }
      if (this.search !== '') {
        let authorNameSearchString = this.search
        authorNameSearchString = authorNameSearchString.toLowerCase()
        sorted = sorted.filter(function (item) {
          if (item !== null && typeof item.forum.title !== 'undefined' && item.forum.title !== null) {
            if ((item.forum.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
              // console.log(item)
              return item
            }
          }
        })
      }
      return sorted
    }
  },
  methods: {
    getAllForums () { // GET ALL FORUM LIST
      http.get(apiRoutes.baseURL + apiRoutes.getAllForum, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        // console.log(response)
        this.forums = response
        if (this.forums.length === 0) {
          this.isLoading = false
          this.nothing = true
        } else {
          this.isLoading = false
          this.nothing = false
        }
      }).catch(error => {
        console.log(error)
      })
    },
    closeSuccess (value) { // CLOSE SUCCESS ADD POPUP
      this.successAdd = value
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
    openSuccessAdd (value) { // OPEN SUCCESS ADD POPUP
      this.successAdd = value
    },
    onCancel () {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .forum {
    padding: 2rem 4rem 4rem 1.5rem;
  }
  .loading {
    position: relative;
    height: 10vh;
  }
  .not_empty {
    height: auto;
  }
</style>
